import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import Auth from "../../helpers/Auth";
import Footer from "../component/Footer";
import { useLocation, useNavigate } from "react-router-dom";

export default function Course() {
  const { http, user, errorHandle } = Auth();
  const [courses, setCourses] = useState([]);
  const location = useLocation();
  const [coursesFilter, setCoursesFilter] = useState({
    trending: [],
    investing: [],
    crypto: [],
    other: [],
    all: [],
    watchlist: [],
  });
  const navigate = useNavigate();
  const [state, setState] = useState({
    type: "all",
  });

  useEffect(() => {
    // console.log(location)
    let investing = [];
    let crypto = [];
    let other = [];
    let trending = [];
    let watchlist = [];
    http
      .get(`/courses/${user.id}`)
      .then((res) => {
        // console.log(res.data.data);

        res.data.data.forEach((el) => {
          // console.log(el.type);
          if (el.type === "investing") {
            investing.push(el);
          } else if (el.type === "other") {
            other.push(el);
          } else if (el.type === "crypto") {
            crypto.push(el);
          }
          if (el.is_trending) {
            trending.push(el);
          }
          // console.log(el.user_complete_lessons)
          if (el.user_complete_lessons.length > 0) {
            watchlist.push(el);
          }
        });

        if (location.state && location.state.watchlist === "active") {
          setState({ ...state, type: "watchlist" });
          setCourses(watchlist);
        } else {
          setCourses(res.data.data);
        }
        setCoursesFilter({
          trending: trending,
          investing: investing,
          crypto: crypto,
          other: other,
          all: res.data.data,
          watchlist: watchlist,
        });
      })
      .catch((err) => {
        console.log(err);
        errorHandle(err);
      });
  }, []);

  const filterCourse = (type) => {
    setState({ ...state, type: type });
    setCourses(coursesFilter[type]);
    // console.log(coursesFilter)
  };

  return (
    <>
      <div className="main_container">
        <Header />
        <div className="chapter_box">
          <div className="courses_nav">
            <ul>
              <li
                className={state.type === "all" ? "active" : ""}
                onClick={() => filterCourse("all")}
              >
                All
              </li>
              <li
                className={state.type === "trending" ? "active" : ""}
                onClick={() => filterCourse("trending")}
              >
                Trending
              </li>
              {coursesFilter.watchlist &&
                coursesFilter.watchlist.length > 0 && (
                  <li
                    className={state.type === "watchlist" ? "active" : ""}
                    onClick={() => filterCourse("watchlist")}
                  >
                    Watchlist
                  </li>
                )}

              <li
                className={state.type === "investing" ? "active" : ""}
                onClick={() => filterCourse("investing")}
              >
                Investing
              </li>
              <li
                className={state.type === "crypto" ? "active" : ""}
                onClick={() => filterCourse("crypto")}
              >
                Crypto
              </li>
              <li
                className={state.type === "other" ? "active" : ""}
                onClick={() => filterCourse("other")}
              >
                Other
              </li>
            </ul>
          </div>
          {courses && (
            <div className="courses">
              <div className="courses_titleBar">
                <p>{state.show_course}</p>
              </div>

              <div className="courses_innerBox">
                {courses.map((el) => {
                  return (
                    <div
                      key={el.id}
                      className="courses_box"
                      onClick={
                        el.user_complete_lessons.length > 0
                          ? () =>
                              navigate(
                                `/courses/${el.user_complete_lessons[0].course_id}/${el.user_complete_lessons[0].chapter_id}/${el.user_complete_lessons[0].lesson_id}`
                              )
                          : () => navigate(`/courses/${el.id}/${1}/${1}`)
                      }
                    >
                      <div className="image_side">
                        <div className="img_box">
                          <img src={el.image} alt="courses" />
                        </div>
                        <p className={`tag tag_${el.level}`}>
                          {el.level.charAt(0).toUpperCase() + el.level.slice(1)}
                        </p>
                      </div>

                      <div className="content_box">
                        <h4>{el.name}</h4>
                        <ul>
                          <li>{el.total_chapters} chapters</li>
                          <li>
                            <svg height="10" width="10" className="mx-2">
                              <circle
                                cx="4"
                                cy="4"
                                r="2"
                                strokeWidth="3"
                                fill="#6B7280"
                              ></circle>
                            </svg>
                          </li>
                          <li>{el.total_lessons} lessons</li>
                        </ul>
                        <div className="progress-bar">
                          <div
                            className="line"
                            style={{
                              width:
                                el.user_complete_lessons.length > 0
                                  ? (el.user_complete_lessons.length * 100) /
                                      el.total_lessons +
                                    "%"
                                  : "0%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
