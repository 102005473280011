import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import images from "../../Assets/Images";
import { Link, useNavigate } from "react-router-dom";
import Auth from "../../helpers/Auth";
import Skeleton from "../component/Skeleton";
import { fetchWalletBalance } from "../../redux/features/wallet/walletSlice";
import { useDispatch, useSelector } from "react-redux";

export default function Simulator() {
  const navigate = useNavigate();
  const { http, user } = Auth();
  const [state, setState] = useState({
    profit_loss: 0,
  });
  const [showTicker, setShowTicker] = useState();
  const [dataLoading, setDataLoading] = useState(true);
  const balance = useSelector((state) => state.wallet.balance);
  useEffect(() => {
    http
      .get(`/get-active-asset-crypto/${user.id}`)
      .then((res) => {
        console.log(res);

        if (Array.isArray(res.data.data) && res.data.data.length > 0) {
          let x = [];
          res.data.data.forEach((el) => {
            x.push(el);
          });

          setShowTicker(x);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // let timeoutId;
    let isMounted = true;

    const fetchPostData = async () => {
      // console.log(showTicker);
      try {
        const res = await http
          .post("/latest-trade-price-bulk", { data: showTicker })
          .then((res) => {
            // console.log(res.data.data);
            setShowTicker(res.data.data);
            let profit_loss = 0;
            res.data.data.forEach((el) => {
              profit_loss += parseFloat(
                (
                  (el.latest_price - el.avg_place_price) *
                  el.avg_left_qty
                )
              );
            });

            setState({ ...state, profit_loss: profit_loss });
            setDataLoading(false);
          })
          .catch((err) => {
            setDataLoading(false);
            console.log("post failed");
          });
      } catch (error) {
        if (isMounted) {
          setDataLoading(false);
          console.error(error);
        }
      }
    };

    const interval = setInterval(fetchPostData, 5000); // Poll every 10 seconds

    return () => {
      clearInterval(interval);
      isMounted = false;
    };
  }, [showTicker]);

  return (
    <>
      <div className="main_container">
        <Header />
        <div className="chapter_box simulator_box">
          <div className="account_value_box">
            <ul>
              <li>Account value</li>
              <li>
                <p className="demo_tag">DEMO</p>
                <img src={images["question_mark.svg"]} alt="question" />
              </li>
            </ul>
            <div className="value_info_box">
              <h3>
                $<span>{(balance + state.profit_loss).toFixed(2)}</span>
              </h3>
            </div>
            <div className="metrics_box">
              <div className="return_profit_box">
                <h5>Total return</h5>
                <p>+0.03%</p>
              </div>
              <div className="return_profit_box">
                <h5>Total profit/loss</h5>
                <p style={{color:state.profit_loss > 0?"rgb(77, 224, 114)":"rgb(212, 51, 51)"}}>
                  {state.profit_loss > 0
                    ? ` ${"+$" + state.profit_loss.toFixed(2)}`
                    : `${"-$" + (state.profit_loss*-1).toFixed(2)}`}
                </p>
              </div>
            </div>
            <div className="btn_group trade_now_btn">
              <button
                className="btn_theme_1"
                onClick={() => navigate("/simulator/buy-assets")}
              >
                <img src={images["plus.svg"]} alt="plus" />
                <span>TRADE NOW</span>
              </button>
            </div>
          </div>
          <div className="active_trads_box">
            <div className="active_trad_titleBar">
              <p>Active Trades</p>
              <span>{showTicker && showTicker.length} items</span>
            </div>
          </div>

          <div className="asset_container">
            {dataLoading ? (
              <Skeleton />
            ) : (
              <ul>
                {showTicker &&
                  showTicker.map((el) => {
                    return (
                      <li key={el.id}>
                        <Link
                          className=""
                          to={`/simulator/buy-assets/${el.ticker_name}`}
                        >
                          <div className="info_box">
                            <div className="img_box">
                              <img src={el.image} alt="company_logo" />
                            </div>
                            <div className="content_box">
                              <p className="name">{el.name}</p>
                              <p className="ticker">
                                {el.close_price + " " + el.ticker_name}
                              </p>
                            </div>
                          </div>
                          <div className="costs_box">
                            <p className="cost">${el.total_value}</p>
                            {el.latest_price - el.avg_place_price > 0 ? (
                              <p className="pnl blue">
                                {(
                                  (el.latest_price - el.avg_place_price) *
                                  el.avg_left_qty
                                ).toFixed(2)}
                              </p>
                            ) : (
                              <p className="pnl red">
                                {(
                                  (el.latest_price - el.avg_place_price) *
                                  el.avg_left_qty
                                ).toFixed(2)}
                              </p>
                            )}
                          </div>
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
