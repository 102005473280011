import React, { useState, useEffect } from "react";
import images from "../../Assets/Images";
import { useLocation, useNavigate } from "react-router-dom";
import Auth from "../../helpers/Auth";
import ChatLoader from "../loader/ChatLoader";

export default function ChatPopUp() {
  const navigate = useNavigate();
  const location = useLocation();
  const { http, user } = Auth();
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [state, setState] = useState({
    session_id: null,
    bot_response: false,
  });

  const closeChatPopUp = () => {
    console.log(location)
    if (location.state) {
      navigate(`${location.state.path}`);
    } else {
      navigate(`/`);
    }
  };

  useEffect(() => {
    http
      .post(`/user-chat`, {
        user_id: user.id,
        message: "",
      })
      .then((res) => {
        console.log(res);
        if (res.data.data.conversation_history) {
          let data = res.data.data.conversation_history;
          data.shift();

          setMessages(data);
        } else {
          setState({
            ...state,
            session_id: res.data.data.session_id,
            first_msg: res.data.data.message,
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);
  const handleSubmit = async (e) => {
    // console.log(input, state);
    e.preventDefault();
    if (!input.trim()) return;
    setInput("");
    const userMessage = { role: "user", content: input };
    setMessages([...messages, userMessage]);
    setState({
      ...state,
      bot_response: true,
    });
    http
      .post(`/user-chat`, {
        session_id: state.session_id,
        message: input,
        user_id: user.id,
      })
      .then((response) => {
        console.log(response);
        const botMessage = { role: "bot", content: response.data.data.message };

        // console.log(result);
        setState({
          ...state,
          bot_response: false,
          session_id: response.data.data.session_id,
        });
        setMessages([...messages, userMessage, botMessage]);
      })
      .catch((error) => {
        console.error("Error:", error.response);
      });
  };
  return (
    <>
      <div className="main_container">
        <div className="top_header">
          <div className="close" onClick={closeChatPopUp}>
            <img src={images["close.svg"]} alt="class" />
          </div>
          <div className="right_site">
            <button className="bar_btn" id="bar_btn">
              <img
                src={images["question_mark.svg"]}
                alt="question"
                id="question"
              />
            </button>
          </div>
          <nav className="top_navbar">
            <ul className="nav">
              <li className="active">
                <img src={images["nav_stady_map.png"]} alt="map" />
                Study map
              </li>
              <li>
                <img src={images["nav_courses.png"]} alt="map" />
                Courses
              </li>
              <li>
                <img src={images["nav_challenges.png"]} alt="map" />
                Challenges
              </li>
              <li>
                <img src={images["nav_books.png"]} alt="map" />
                Books
              </li>
            </ul>
          </nav>
        </div>

        <div className="chapter_box">
          <div className="chat_header">
            <div className="ai_img_box">
              <img src={images["ai_chat_img.png"]} alt="ai_chat" />
            </div>
            <h3>Finiva</h3>
            <p>
              {state.first_msg
                ? state.first_msg
                : `Your personal AI (Artificial Intelligence) powered investing
              mentor. Finiva is not fine-tuned to provide investment advice. You
              should not rely on our feedbacks as a sole source of investment
              advice.`}
            </p>
          </div>

          <div className="chat_box">
            {messages &&
              messages.map((msg, index) => {
                return (
                  <div key={index}>
                    {msg.role === "user" ? (
                      <div className="your_text">
                        <p>{msg.content}</p>
                      </div>
                    ) : (
                      <div className="ai_text">
                        <p>{msg.content}</p>
                      </div>
                    )}
                  </div>
                );
              })}
            {state.bot_response === true && (
              <div className="ai_text">
                <p>
                  <ChatLoader />
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {(messages.length > 0 || state.first_msg) && (
        <div className="chat_input_container">
          <div className="input_chat">
            <textarea
              cols="1"
              rows="1"
              placeholder="Write a message ..."
              onChange={(e) => setInput(e.target.value)}
              disabled={state.bot_response === true ? true : false}
            >
              {input}
            </textarea>
          </div>
          <div className="chat_sendBox">
            <button onClick={handleSubmit}>
              <img src={images["send.svg"]} alt="send" />
            </button>
          </div>
        </div>
      )}
    </>
  );
}
