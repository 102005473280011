
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

// Fetch wallet balance from API
export const fetchWalletBalance = createAsyncThunk("coin/fetchBalance", async (userId, { rejectWithValue }) => {
	try {
		let BASE_URL = "https://api.finiva.io/api/v1";

		if (process.env.NODE_ENV === "development") {
		  BASE_URL = "http://localhost:8000/api/v1"; //process.env.BASE_URL;
		} else if (process.env.NODE_ENV === "production") {
		  BASE_URL = "https://api.finiva.io/api/v1";
		}
		const response = await axios.get(`${BASE_URL}/coin/${userId}`, {
			headers: {
				"Content-type": "application/json",
				accessToken: `Bearer ${localStorage.getItem("token")}`,
			},
		})
		return response.data.data.coin_balance
	} catch (error) {
		return rejectWithValue(error.response.data.status)
	}
})

// Update wallet balance in the backend API
// export const updateWalletBalance = createAsyncThunk("coin/updateBalance", async ({ userId, amount }, { getState, rejectWithValue }) => {
// 	try {
// 		// Assuming you have an authentication token stored in Redux state or localStorage
// 		const response = await axios.post(
// 			`http://localhost:8000/api/v1/coin/update`,
// 			{ userId, amount },
// 			{
// 				headers: {
// 					Authorization: `Bearer ${localStorage.getItem("token")}`, // Add your authorization token
// 					"Content-Type": "application/json", // Set content type to JSON
// 				},
// 			}
// 		)

// 		return response.data.balance // Assuming balance is returned in response.data.balance
// 	} catch (error) {
// 		const errorMessage = error.response?.data?.message || error.message || "Unknown error occurred"
// 		return rejectWithValue(errorMessage)
// 	}
// })

const walletSlice = createSlice({
	name: "wallet",
	initialState: {
		balance: 0,
		status: "idle", // idle, loading, succeeded, failed
		error: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			// Fetch wallet balance
			.addCase(fetchWalletBalance.pending, (state) => {
				state.status = "loading"
			})
			.addCase(fetchWalletBalance.fulfilled, (state, action) => {
				state.status = "succeeded"
				state.balance = action.payload
			})
			.addCase(fetchWalletBalance.rejected, (state, action) => {
				state.status = "failed"
				state.error = action.payload
			})
			// Update wallet balance
			// .addCase(updateWalletBalance.pending, (state) => {
			// 	state.status = "loading"
			// })
			// .addCase(updateWalletBalance.fulfilled, (state, action) => {
			// 	state.status = "succeeded"
			// 	state.balance = action.payload
			// })
			// .addCase(updateWalletBalance.rejected, (state, action) => {
			// 	state.status = "failed"
			// 	state.error = action.payload
			// })
	},
})

export default walletSlice.reducer