import React, { useEffect, useState } from "react";
import images from "../../Assets/Images";
import { useNavigate, useParams } from "react-router-dom";
import Auth from "../../helpers/Auth";

export default function BooksChapter() {
  const [state, setState] = useState({
    slide: false,
    step: 0,
    chapter_complete: false,
  });
  const [chapter, setChapter] = useState();
  const [chapterContent, setChapterContent] = useState();
  const navigate = useNavigate();
  const { http, user } = Auth();
  const params = useParams();
  const closeChapter = () => {
    if (state.slide) {
      setState({ ...state, slide: false });
    } else {
      navigate(`/books`);
    }
  };
  useEffect(() => {
    http
      .get(`/book-details-chapter/${params.book_id}`)
      .then(async (res) => {
        console.log(res);
        let x = await http.post(`/books-read-finish`, {
          user_id: user.id,
          book_id: res.data.data.id,
          book_chapter_id: res.data.data.book_chapters[0].id,
        });
        setChapter(res.data.data);
        setState({
          ...state,
          total_chapters: res.data.data.book_chapters.length,
        });
        setChapterContent(res.data.data.book_chapters[state.step]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const nextContent = async() => {
    // console.log(chapterContent)
    if (!state.chapter_complete) {
      let chapter_complete = false;
      if (state.step + 1 === state.total_chapters - 1) {
        chapter_complete = true;
      }
      let x = await http.post(`/books-read-finish`, {
        user_id: user.id,
        book_id: chapter.id,
        book_chapter_id: chapter.book_chapters[state.step + 1].id,
      });
      // console.log(state.step);
      setChapterContent(chapter.book_chapters[state.step + 1]);
      setState({
        ...state,
        step: state.step + 1,
        chapter_complete: chapter_complete,
      });
    }

    // console.log(state);
  };
  const prevContent = () => {
    // console.log(state.step,chapter.book_chapters[11]);
    console.log(state);
    if (state.step > 0) {
      setChapterContent(chapter.book_chapters[state.step - 1]);
      setState({
        ...state,
        step: state.step - 1,
        chapter_complete: false,
      });
    }
  };
  const setChapterHandler = (index) => {
    let chapter_complete = false;
    // console.log(index,state.total_chapters)
    if (index + 1 === state.total_chapters) {
      chapter_complete = true;
    }
    setState({
      ...state,
      slide: false,
      step: index,
      chapter_complete: chapter_complete,
    });
    setChapterContent(chapter.book_chapters[index]);
  };
  const sideBar = () => {
    setState({ ...state, slide: !state.slide });
    if (!state.slide) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  return (
    <div className="main_container">
      <div className="top_header_book_inner pop_up">
        <div className="header_book_inner_wrapper">
          <div className="book_inner_left_arrow" onClick={closeChapter}>
            <img
              src={images["book_inner_pop_up_cross.png"]}
              alt="img"
              className="img_fluid"
            />
          </div>
          <div className="book_header_text">
            <p>{chapter && chapter.name}</p>
          </div>
          <div className="book_inner_sideBar" onClick={sideBar}>
            <img
              src={images["book_inner_pop_up_side_bar.png"]}
              alt="img"
              className="img_fluid"
            />
          </div>
        </div>
      </div>
      <div
        className="book_details_popUp"
        style={state.slide ? { opacity: 0 } : { opacity: 1 }}
      >
        <p className="book_details_popUp_head">
          {chapterContent && chapterContent.name}
        </p>
        <p className="book_details_popUp_para">
          {chapterContent &&
            typeof chapterContent.content === "string" &&
            JSON.parse(chapterContent.content).text}
          {chapterContent &&
            typeof chapterContent.content === "object" &&
            chapterContent.content.text}
        </p>

        <div className="pagination">
          {state.step > 0 && (
            <div className="page_arrow" onClick={prevContent}>
              <img
                src={images["arrow.svg"]}
                style={{ transform: "rotate(180deg)" }}
                alt=""
              />
            </div>
          )}

          <div className="page_number_div">
            <p className="page_number">
              <span className="pageIn">{state.step + 1}</span> of{" "}
              <span className="page_to">
                {state.total_chapters && state.total_chapters}
              </span>
            </p>
          </div>
          {!state.chapter_complete && (
            <div className="page_arrow" onClick={nextContent}>
              <img src={images["arrow.svg"]} alt="" />
            </div>
          )}
        </div>
      </div>

      <div
        className="book_lessonList_popup"
        style={state.slide ? { opacity: 1 } : { opacity: 0 }}
      >
        <p className="book_details_top_text">What's inside?</p>
        {chapter &&
          chapter.book_chapters.length > 0 &&
          chapter.book_chapters.map((el, index) => {
            return (
              <div
                key={el.id}
                className="inside_box"
                onClick={() => setChapterHandler(index)}
              >
                <div className="inside_box_number">
                  <p>{el.order_no}</p>
                </div>
                <div className="inside_box_text">
                  <p>{el.name}</p>
                </div>
                {el.user_complete_books.length > 0 && (
                    <div className="inside_box_image">
                      <img
                        src={images["inside_box_check_icon.png"]}
                        alt="img"
                        className="img_fluid"
                      />
                    </div>
                  )}
              </div>
            );
          })}
      </div>
    </div>
  );
}
