import React, { useState, useEffect } from "react";
import images from "../../../Assets/Images";

export default function QuizPage({ lesson, lessonStep, lessonState }) {
  const [content, setContent] = useState();
  const [state, setState] = useState({});

  useEffect(() => {
    lessonState((prev) => {
      return { ...prev, lesson_step: false };
    });
    if (typeof lesson.content === "string") {
      let x = JSON.parse(lesson.content);
      if (!x.answer) {
        x.answer = false;
      }
      setContent(x);
    } else {
      if (!lesson.content.answer) {
        lesson.content.answer = false;
      }
      setContent(lesson.content);
    }
  }, [lesson.content]);
  console.log(content);

  const myResponse = (res) => {
    setState({ ...state, options: res });

    setTimeout(() => {
      lessonStep("add_by_one");
      lessonState((prev) => {
        return { ...prev, lesson_step: true };
      });
    }, 400);
  };
  return (
    <>
      <div className="content_box">
        {content && (
          <>
            {content.title && <h3 className="title">{content.title}</h3>}
            {content.image && (
              <div className="img_box">
                <img src={content.image} alt="chapter" />
              </div>
            )}
            {content.text && <p className="text">{content.text}</p>}

            <div className="selectBox_container">
              <label
                for="ans1"
                className={
                  state.options === "true" &&
                  (content.answer === true ? "correct" : "incorrect")
                }
                onClick={() => myResponse("true")}
              >
                <input type="radio" name="ans" id="ans1" />

                <p>true</p>
                <div className="check_box"></div>
              </label>
              <label
                for="ans2"
                className={
                  state.options === "false" &&
                  (content.answer === false ? "correct" : "incorrect")
                }
                onClick={() => myResponse("false")}
              >
                <input type="radio" name="ans" id="ans2" />

                <p>false</p>
                <div className="check_box"></div>
              </label>
            </div>
          </>
        )}
      </div>
      <div>
        {state.options &&
          (state.options === content.answer ? (
            <div class="correct_popup">
              <div class="img_box">
                <img src={images["correct.svg"]} alt="" />
              </div>
              <div class="content_box">
                <p>Good job</p>
              </div>
            </div>
          ) : (
            <div class="incorrect_popup">
              <div class="img_box">
                <img src={images["incorrect.svg"]} alt="" />
              </div>
              <div class="content_box">
                <p>Oops</p>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
