import React, { useEffect, useState } from "react";
import images from "../../Assets/Images";
import Footer from "../component/Footer";
import Auth from "../../helpers/Auth";

export default function Leaderboard() {
  const {http} = Auth();
  const [leagues,setLeagues] = useState();
  const [state,setState] = useState({
    leageDescription:"",
    league_name:""
  });
  const [isAboutPopupVisible, setIsAboutPopupVisible] = useState(false);
  const [isQuestionPopupVisible, setIsQuestionPopupVisible] = useState(false);
  const [isAboutPopupActive, setIsAboutPopupActive] = useState(false);
  const [isQuestionPopupActive, setIsQuestionPopupActive] = useState(false);
  useEffect(()=>{
    http.get('/league').then((res)=>{
console.log(res.data.data);
setLeagues(res.data.data)
    }).catch((err)=>{
      console.log(err)
    })
  },[])
  // Show and activate the "About" popup with delay
  const showAboutPopup = (el) => {
    setState({...state,leageDescription:el.description,league_name:el.name})
    setIsAboutPopupVisible(true);
    setTimeout(() => {
      setIsAboutPopupActive(true);
    }, 100);
  };

  // Hide and deactivate the "About" popup with delay
  const hideAboutPopup = () => {
    setIsAboutPopupActive(false);
    setTimeout(() => {
      setIsAboutPopupVisible(false);
    }, 100);
  };

  // Show and activate the "Question" popup with delay
  const showQuestionPopup = () => {
    setIsQuestionPopupVisible(true);
    setTimeout(() => {
      setIsQuestionPopupActive(true);
    }, 100);
  };

  // Hide and deactivate the "Question" popup with delay
  const hideQuestionPopup = () => {
    setIsQuestionPopupActive(false);
    setTimeout(() => {
      setIsQuestionPopupVisible(false);
    }, 100);
  };
  return (
    <>
      <div className="main_container">
        <div className="top_header">
          <div></div>
          <div className="book_header_text">
            <p>Leaderboard</p>
          </div>
          <div className="coin_store" onClick={showQuestionPopup}>
            <img
              src={images["question_mark.svg"]}
              alt="question"
              id="question"
            />
          </div>
        </div>
        <div className="leaderboard_header">
          <div className="announce_container">
            {
              leagues && leagues.map((el)=>{
                return (
                  <div key={el.id} className="announce_imgBox" onClick={()=>showAboutPopup(el)}>
                  <img src={el.image?el.image:images["download.svg"]} alt="passed" />
                </div>
                )
              })
            }
           
         
         
          </div>
          <div className="announce_text">
            <h2>Apprentice Traders League</h2>
            <p>Top 10 advance to the next league</p>
            <div className="announce_scoreBox">
              <p>
                Challenge ends in: <span>7 hours</span>
              </p>
            </div>
          </div>
        </div>
        <div className="announceList_container">
          <div className="announceList_box">
            <div className="left">
              <div className="rank">
                <img src={images["rank1.svg"]} alt="rank1" />
              </div>
              <div className="profile_img">
                <img src={images["7.png"]} alt="" />
              </div>
              <div className="profile_name">
                <p>vjordan</p>
              </div>
            </div>
            <div className="right">
              <p>$38,774</p>
            </div>
          </div>
          <div className="announceList_box">
            <div className="left">
              <div className="rank">
                <img src={images["rank2.svg"]} alt="rank2" />
              </div>
              <div className="profile_img">
                <img src={images["22.png"]} alt="" />
              </div>
              <div className="profile_name">
                <p>vjordan</p>
              </div>
            </div>
            <div className="right">
              <p>$38,774</p>
            </div>
          </div>
          <div className="announceList_box">
            <div className="left">
              <div className="rank">
                <img src={images["rank3.svg"]} alt="rank3" />
              </div>
              <div className="profile_img">
                <img src={images["24.png"]} alt="" />
              </div>
              <div className="profile_name">
                <p>vjordan</p>
              </div>
            </div>
            <div className="right">
              <p>$38,774</p>
            </div>
          </div>
          <div className="announceList_box active">
            <div className="left">
              <div className="rank">
                <p>4</p>
              </div>
              <div className="profile_img">
                <img src={images["7.png"]} alt="" />
              </div>
              <div className="profile_name">
                <p>vjordan</p>
              </div>
            </div>
            <div className="right">
              <p>$38,774</p>
            </div>
          </div>
          <div className="announceList_box">
            <div className="left">
              <div className="rank">
                <p>5</p>
              </div>
              <div className="profile_img">
                <img src={images["7.png"]} alt="" />
              </div>
              <div className="profile_name">
                <p>vjordan</p>
              </div>
            </div>
            <div className="right">
              <p>$38,774</p>
            </div>
          </div>

          <div className="promotion_zone">
            <div className="line"></div>
            <p>Promotion zone</p>
            <div className="line"></div>
          </div>
          <div className="announceList_box">
            <div className="left">
              <div className="rank">
                <p>5</p>
              </div>
              <div className="profile_img">
                <img src={images["7.png"]} alt="" />
              </div>
              <div className="profile_name">
                <p>vjordan</p>
              </div>
            </div>
            <div className="right">
              <p>$38,774</p>
            </div>
          </div>
          <div className="demotion_zone">
            <div className="line"></div>
            <p>Demotion zone</p>
            <div className="line"></div>
          </div>
          <div className="announceList_box">
            <div className="left">
              <div className="rank">
                <p>5</p>
              </div>
              <div className="profile_img">
                <img src={images["7.png"]} alt="" />
              </div>
              <div className="profile_name">
                <p>vjordan</p>
              </div>
            </div>
            <div className="right">
              <p>$38,774</p>
            </div>
          </div>
          <div className="announceList_box">
            <div className="left">
              <div className="rank">
                <p>5</p>
              </div>
              <div className="profile_img">
                <img src={images["7.png"]} alt="" />
              </div>
              <div className="profile_name">
                <p>vjordan</p>
              </div>
            </div>
            <div className="right">
              <p>$38,774</p>
            </div>
          </div>
        </div>
      </div>

      {isQuestionPopupVisible && (
        <div
          className="popup_leaderBoard"
          id="question_popup"
          style={{ display: "block" }}
        >
          <div
            className={`popupContent_box ${
              isQuestionPopupActive ? "active" : ""
            }`}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
          >
            <img src={images["help_bottomsheet.png"]} alt="help_bottomsheet" />
            <div className="popup_text">
              <h3>What is a leaderboard?</h3>
              <p>
                Leaderboard enables users to compare progress, track
                improvement, and compete for top positions. Complete courses and
                excel on the simulator to climb the leaderboard and advance in
                the leagues.
              </p>
              <div className="read_more_btn">
                <button type="button" onClick={hideQuestionPopup}>
                  GOT IT
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isAboutPopupVisible && (
        <div
          className="popup_leaderBoard"
          id="about_popup"
          style={{ display: "block" }}
        >
          <div
            className={`popupContent_box ${isAboutPopupActive ? "active" : ""}`}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
          >
            <div className="popup_text">
              <h3>{state.league_name}</h3>
              <p>
                {
                   state.leageDescription
                }
              </p>
              <div className="read_more_btn">
                <button type="button" onClick={hideAboutPopup}>
                  GOT IT
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}
