import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import HeaderNav from "../component/HeaderNav";
import Footer from "../component/Footer";
import { useNavigate } from "react-router-dom";
import Auth from "../../helpers/Auth";
import images from "../../Assets/Images";

export default function Challenges() {
  const navigate = useNavigate();
  const { http, user, errorHandle } = Auth();
  const [chalenge, setChallenge] = useState();
  const [state,setState] = useState({
    progres:0
  })
  useEffect(() => {
    http
      .get(`/challenges/${user.id}`)
      .then((res) => {
        console.log(res.data.data);
        setChallenge(res.data.data[0]);
        let total_day = 0
        let complete = 0;
        res.data.data[0].daily_challenges.forEach(el => {
          if(el.user_complete_challenges.length>0)
          {
            complete+=1;
          }
          total_day+=1;
        });
        setState({...state,progres:complete*100/total_day})
      })
      .catch((err) => {
        // console.log(err);
        errorHandle(err);
      });
  }, []);
  const joinChalenge = () => {
    http
      .post(`/user-join-challenge`, {
        user_id: user.id,
        challenge_id: chalenge.id,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    navigate(`/challenges/${chalenge.id}`);
  };

  return (
    <>
      <div className="main_container">
        <Header />
        <div className="chapter_box">
          <div className="challenges_box">
            <h3>{chalenge && chalenge.name}</h3>
            <ul>
              <li>
                <p className="tag tag_beginner">{chalenge && chalenge.level}</p>
              </li>
              <li>
                <svg height="10" width={Math.round(state.progres)} className="mx-2">
                  <circle
                    cx="4"
                    cy="4"
                    r="2"
                    strokeWidth="3"
                    fill="#6B7280"
                  ></circle>
                </svg>
              </li>
              <li>{chalenge && chalenge.duration}</li>
            </ul>
            <div className="progress-bar">
              <div className="line"></div>
            </div>
            <div className="image_box">
              <img src={chalenge && chalenge.image} alt="" />
            </div>
            <div className="reward_box">
              <p>Reward for challenge:</p>
              <ul>
                <li>
                  <img src={images["coin.svg"]} alt="coin" />
                </li>
                <li>
                  $<span>{chalenge && chalenge.coins_reward}</span>
                </li>
              </ul>
            </div>
            <div className="btn_group">
              {chalenge && chalenge.user_join_challenge ? (
                <button
                  className="btn_theme_2"
                  onClick={() => navigate(`/challenges/${chalenge.id}`)}
                >
                  VIEW
                </button>
              ) : (
                <button className="btn_theme_1" onClick={joinChalenge}>
                  JOIN NOW
                </button>
              )}
            </div>
          </div>
          <div className="challenges_comingSoon">
            <p>COMING SOON</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
