import React, { useState } from "react";
import Auth from "../../helpers/Auth";

export default function TestInertQuizAnser() {
  const { http } = Auth();
  const [input, setInput] = useState({
    url: "",
    question: "",
    answer: "",
  });
  const [error, setError] = useState({});
  const [state, setState] = useState({
    btn_disable:false
  });
  const submitAnswer = () => {
    if (input.url && input.question && input.answer) {
        setState({...state,btn_disable:true})
      console.log(input);
      let answer = input.answer;
      let answer_arr = answer.split(":");
        console.log(answer_arr)
      if (Array.isArray(answer_arr)) {
        let postion = answer_arr.length - 1;
        answer = answer_arr[postion];
      }
        console.log(answer)
      http
        .post(`/insert-quiz-answer`, {
          url: input.url,
          question: input.question,
          answer: answer,
        })
        .then((res) => {
          console.log(res);
          setState({...state,btn_disable:false})
          if (res.data.message) {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setError({ ...error, error: "all field required" });
    }
  };
  return (
    <div className="container" style={{ maxWidth: "720px" }}>
      <form className="login_form">
        <div className="input_box">
          <label htmlFor="email">Url</label>
          <input
            type="text"
            name="url"
            placeholder="enter url"
            value={input.url}
            onChange={(e) => setInput({ ...input, url: e.target.value })}
          />
        </div>
        <div className="input_box">
          <label htmlFor="email">Question</label>
          <input
            type="text"
            name="url"
            placeholder="enter question"
            value={input.question}
            onChange={(e) => setInput({ ...input, question: e.target.value })}
          />
        </div>
        <div className="input_box">
          <label htmlFor="email">Answer</label>
          <input
            type="text"
            name="url"
            placeholder="enter correct answer"
            value={input.answer}
            onChange={(e) => setInput({ ...input, answer: e.target.value })}
          />
        </div>

        <div
          style={{
            marginTop: "18px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            type="button"
            className="btn_newtheme"
            value="submit"
            disabled={state.btn_disable&&"true"}
            onClick={submitAnswer}
          />
        </div>
        <div style={{ marginTop: "18px", color: "red" }}>{error.error}</div>
      </form>
    </div>
  );
}
