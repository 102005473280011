import { useState } from "react";
import images from "../../Assets/Images";
import { Link, useNavigate } from "react-router-dom";
import Auth from "../../helpers/Auth";

export default function Settings() {
  const { token, logout, user } = Auth();
  const navigate = useNavigate();
  const [currentYear, setCurrentYear] = useState(getDate());
  function getDate() {
    const today = new Date();
    return today.getFullYear();
  }
  function logoutUser() {
    // console.log(token)
    if (token != undefined) {
      logout();
    }
  }
  return (
    <div className="profile_section">
      <div className="container_large  padding-0">
        <div className="profile_header">
          <div onClick={(e) => navigate("/profile")}>
            <i className="fa fa-angle-left"></i>
          </div>

          <p>Settings</p>
          <div></div>
        </div>
        <div className="profile_body">
          {/* <Link onClick={(e) => {
                        e.preventDefault();
                        navigate("/profile/reports");
                    }} title="" className="settings_menu_item">
                        <div className="profile_menu_item">
                            <div className="profile_item_icon">
                                <img src={images['reports.svg']} alt="reports" />
                                <p>Reports</p>
                            </div>
                            <i className="fa fa-angle-right"></i>
                        </div>
                    </Link> */}
          <a
            href="https://finiva.io/terms.html"
            target="_blank"
            title="Terms and Conditions"
            className="settings_menu_item"
          >
            <div className="profile_menu_item settings_menu_item">
              <div className="profile_item_icon">
                <img
                  src={images["terms_condition.svg"]}
                  alt="terms_condition"
                />
                <p>Terms and Conditions</p>
              </div>
              <i className="fa fa-angle-right"></i>
            </div>
          </a>
          <a
            href="https://finiva.io/privacy.html"
            target="_blank"
            title="Privacy Policy"
            className="settings_menu_item"
          >
            <div className="profile_menu_item settings_menu_item">
              <div className="profile_item_icon">
                <img src={images["lock.svg"]} alt="privacy_policy" />
                <p>Privacy Policy</p>
              </div>
              <i className="fa fa-angle-right"></i>
            </div>
          </a>
          <a
            href="https://finiva.io/subscription.html"
            target="_blank"
            title="Subscription Terms"
            className="settings_menu_item"
          >
            <div className="profile_menu_item settings_menu_item">
              <div className="profile_item_icon">
                <img src={images["subscription_terms.svg"]} alt="terms" />
                <p>Subscription Terms</p>
              </div>
              <i className="fa fa-angle-right"></i>
            </div>
          </a>
          <Link
            onClick={(e) => {
              e.preventDefault();
              navigate("/profile/subscription-plan");
            }}
            state={{ page: "plan-subscription" }}
            className="settings_menu_item"
          >
            <div className="profile_menu_item settings_menu_item">
              <div className="profile_item_icon">
                <img src={images["subcriptionsvg.svg"]} alt="sunbcription" />
                <p>Subscription</p>
              </div>
              <i className="fa fa-angle-right"></i>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              e.preventDefault();
              navigate("/profile/language");
            }}
            className="settings_menu_item"
          >
            <div className="profile_menu_item settings_menu_item">
              <div className="profile_item_icon">
                <img src={images["language_icon.svg"]} alt="Language" />
                <p>Language</p>
              </div>
              <i className="fa fa-angle-right"></i>
            </div>
          </Link>
          <div
            onClick={logoutUser}
            className="settings_menu_item"
            style={{ marginTop: "13px",cursor:"pointer" }}
          >
            <div className="profile_menu_item settings_menu_item">
              <div className="profile_item_icon">
                <img src={images["exit_icon.svg"]} alt="help" />
                <p className="danger_text">log out</p>
              </div>
            </div>
          </div>
          <p className="copy_right_text">
            ©{currentYear} finiva. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}
