import React, { useEffect } from "react";
import images from "../../Assets/Images";
import Auth from "../../helpers/Auth";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletBalance } from "../../redux/features/wallet/walletSlice";


export default function Coin({ only_balance = false }) {
  const { user } = Auth();
  const dispatch = useDispatch();
  const balance = useSelector((state) => state.wallet.balance);
  // const status = useSelector((state) => state.wallet.status);
  // const error = useSelector((state) => state.wallet.error);

  useEffect(() => {
    dispatch(fetchWalletBalance(user.id));
    console.log(balance)
  }, [dispatch, user.id]);

  if (only_balance) {
    return balance;
  }
  return (
    <div className="coin_box">
      <img src={images["dollar.png"]} alt="dollar" />
      <p>{balance}</p>
    </div>
  );
}
