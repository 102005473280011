import React, { useState, useEffect } from "react";
import images from "../../../Assets/Images";
import Auth from "../../../helpers/Auth";

export default function Quiz({ lesson, lessonStep, lessonState }) {
  const { http, user } = Auth();
  const [content, setContent] = useState();
  const [state, setState] = useState({
    submit: false,
    options: -1,
  });

  useEffect(() => {
    lessonState((prev) => {
      return { ...prev, lesson_step: false };
    });
    if (typeof lesson.content === "string") {
      let x = JSON.parse(lesson.content);

      setContent(x);
    } else {
      setContent(lesson.content);
    }
  }, [lesson.content]);

  console.log(lesson);
  const myResponse = async (index, res) => {
    setState({ ...state, options: index, response: res });
  };
  const submitAnswer = async () => {
    try {
      let response = await http.get(`/get-quiz-response/${lesson.id}`);

      if (response.data.data) {
        if (state.response == response.data.data) {
          setState({ ...state, answer: true, submit: true });
        } else {
          setState({
            ...state,
            answer: false,
            submit: true,
            corect_answer: response.data.data,
          });
        }
      } else {
        setState({ ...state, answer: true, submit: true });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const nextQuiz = () => {
    setState({ ...state, submit: false,options:-1 });
    lessonStep("add_by_one");
    lessonState((prev) => {
      return { ...prev, lesson_step: true, answer: state.answer };
    });
  };

  return (
    <>
      <div className="content_box" style={{ padding: "0px" }}>
        {content && (
          <>
            {content.text && (
              <h2 style={{ fontSize: "21px" }}>{content.text}</h2>
            )}

            <div
              className="selectBox_container"
              style={{ marginTop: "21px", marginBottom: "21px" }}
            >
              {content.options.map((el, index) => {
                return (
                  <label
                    key={index}
                    htmlFor={"ans" + index}
                    className={
                      state.options === index &&
                      state.submit &&
                      (state.answer ? "correct" : "incorrect")
                    }
                    onClick={() => myResponse(index, el.text)}
                    style={
                      state.submit === false
                        ? state.options === index
                          ? { border: "1px solid #795af6" }
                          : { border: "" }
                        : { border: "" }
                    }
                  >
                    <input type="radio" name="ans" id={"ans" + index} />

                    <p>{el.text}</p>
                    <div className="check_box"></div>
                  </label>
                );
              })}
            </div>

            {state.submit === false && state.options > -1 && (
              <div className="read_more_btn">
                <button type="button" onClick={submitAnswer}>
                  Submit
                </button>
              </div>
            )}
            {state.options > -1 && state.submit && (
              <div className="read_more_btn">
                <button
                  type="button"
                  style={{ backgroundColor: state.answer?"rgb(25, 170, 50)":"#d43333" }}
                  onClick={nextQuiz}
                >
                  Continue
                </button>
              </div>
            )}
          </>
        )}
      </div>
      <div>
        {state.submit &&
          (state.answer ? (
            <div class="correct_popup">
              <div class="img_box">
                <img src={images["correct.svg"]} alt="" />
              </div>
              <div class="content_box">
                <p>Good job</p>
              </div>
            </div>
          ) : (
            <div class="incorrect_popup">
              <div class="img_box">
                <img src={images["incorrect.svg"]} alt="" />
              </div>
              <div class="content_box">
                <p>{`Correct Answer : ${state.corect_answer}`}</p>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
