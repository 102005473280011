import React, { useState } from "react";
import images from "../../Assets/Images";
import Coin from "./Coin";
import { useLocation, useNavigate } from "react-router-dom";


export default function Header() {
  const navigate = useNavigate();
  const location  = useLocation();
  const [state, setState] = useState({
    burger_menu: false,
    chat_toggle: false,
  });

  return (
    <>
      <div className="top_header">
        <div className="logo_box">
          <img src={images["logo.png"]} className="img_fluid" alt="" />
        </div>
        <div className="right_site">
          <button className="chat_btn" onClick={() => navigate(`/chat`,{state:{path:location.pathname}})}>
            <img src={images["chat.png"]} alt="chat" />
          </button>
          {/* <div className="coin_box">
        <img src={images["dollar.png"]} alt="dollar" />
        <p>${coin&&coin.coin}</p>
      </div> */}
          <Coin />
          <button
            className="bar_btn"
            onClick={() =>
              setState({ ...state, burger_menu: !state.burger_menu })
            }
          >
            <img src={images["bar.png"]} alt="bar" />
          </button>
        </div>
        <nav className={state.burger_menu ? "top_navbar active" : "top_navbar"}>
          <ul className="nav">
            {/* <li onClick={() => navigate(`/`)}>
          <img src={images["nav_stady_map.png"]} alt="map" />
          Study map
        </li> */}
            <li onClick={() => navigate(`/courses`)}>
              <img src={images["nav_courses.png"]} alt="map" />
              Courses
            </li>
            <li onClick={() => navigate(`/challenges`)}>
              <img src={images["nav_challenges.png"]} alt="map" />
              Challenges
            </li>
            <li onClick={() => navigate(`/books`)}>
              <img src={images["nav_books.png"]} alt="map" />
              Books
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
