import { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
  matchPath,
} from "react-router-dom";
import Home from "../templates/pages/Home";
import PageNotFound404 from "../templates/component/PageNotFound404";
import Auth from "../helpers/Auth";
import Course from "../templates/pages/Course";
import Challenges from "../templates/pages/Challenges";
import Books from "../templates/pages/Books";
import Profile from "../templates/pages/Profile";
import SubscriptionPlan from "../templates/pages/SubscriptionPlan";
import Settings from "../templates/pages/Settings";
import Language from "../templates/pages/Language";
import LessonContent from "../templates/pages/LessonContent";
import Simulator from "../templates/pages/Simulator";
import BooksInfo from "../templates/pages/BooksInfo";
import BooksChapter from "../templates/pages/BooksChapter";
import BuyAssets from "../templates/pages/BuyAssets";
import StockView from "../templates/pages/StockView";
import FinishLesson from "../templates/component/FinishLesson";
import Leaderboard from "../templates/pages/Leaderboard";
import JoinChallenge from "../templates/pages/JoinChallenge";
import ChallengesTasks from "../templates/pages/ChallengesTasks";
import TestInertQuizAnser from "../templates/pages/TestInertQuizAnser";
import ChatPopUp from "../templates/pages/ChatPopUp";

export default function AuthUser() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout, http } = Auth();
  const [state, setState] = useState({
    coin: 10000,
  });

  // console.log('auth',location);

  useEffect(() => {
    http.get(`/coin/${user.id}`).then((res) => {
      console.log(res,res.data.data.coin_balance);
      if (res.data.data&&res.data.data.coin_balance) {
        setState({ ...state, coin: res.data.data.coin_balance });
      }
    }).catch((err)=>{
      console.log(err)
    });

    if (user) {
      if (user.isplanActive === undefined || user.isplanActive === false) {
        // console.log(user)
        const patterns = [
          "/",
          "/courses/:course_id",
          "/courses/:course_id/:chapter_id/:lesson_id",
          "/courses/:course_id/:chapter_id/:lesson_id/complete",
          "/courses/:course_id/:chapter_id/:lesson_id/survey",
          "/ai-bots",
          "/new-chat/:bot_id",
          "/ai-bots/bot-history",
          "/ai-bots/bot-history/:bot_id/:session_id",
          "/challenges",
          "/challenges/:challenge_id",
          "/challenges/:challenge_id/tasks",
          "/challenges/:challenge_id/:daily_challenge_id",
          "/challenges/:challenge_id/:daily_challenge_id/complete",
        ];

        const match = patterns.reduce(
          (match, pattern) =>
            match ? match : matchPath(pattern, location.pathname),
          null
        );
        // console.log(patterns)
        // navigate(location.pathname);
        if (match !== null) {
          navigate("/profile/subscription-plan");
        }
      }
    } else {
      logout();
    }

    //when user login and access guest route then redirect to navigate('/')
    const guest_routes = [
      "/auth/signin",
      "/auth/register",
      "/auth/forgot",
      "/email-login",
      "/reset-password",
    ];
    if (guest_routes.includes(location.pathname)) {
      navigate("/");
    }
  }, [location.pathname]);

  const VerifyEmail = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let email = searchParams.get("em");
    let token = searchParams.get("t");
    const { http } = Auth();
    // setParamToken(email);
    // console.log(email, token)
    if (email && token) {
      http
        .post("/user/verification", {
          email: email,
          verifyToken: token,
        })
        .then((res) => {
          if (res.data.status === 1) {
            navigate("/auth/signin");
          }
          // console.log(res.data)
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      navigate("/auth/signin");
    }
  };
  const EmailLogin = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let email = searchParams.get("em");
    const { http, setToken, errorHandle } = Auth();
    // setParamToken(email);
    // console.log(email)
    if (email) {
      http
        .post("/user/login/email", {
          email: email,
        })
        .then((res) => {
          // console.log(res.data)

          setToken(
            {
              id: res.data.data.id,
              name: res.data.data.name,
              email: res.data.data.email,
              isplanActive: res.data.data.isplanActive,
              accesChatGPT: res.data.data.accesChatGPT,
            },
            res.data.data.accessToken
          );
        })
        .catch((err) => {
          console.log(err.response);
          errorHandle(err);
        });
    } else {
      navigate("/auth/signin");
    }
  };
  return (
    <>
      <Routes>
      <Route path="/insert-quiz-answer" element={<TestInertQuizAnser />} />
        {user && user.isplanActive === true ? (
          <>
            <Route index path="/" element={<Home coin={state} />} />
            <Route path="/chat" element={<ChatPopUp />} />
            <Route path="/courses" element={<Course />} />
            <Route path="/courses/:course_id" element={<Home  />} />
            <Route path="/challenges" element={<Challenges />} />
            <Route
              path="/challenges/:challenge_id"
              element={<JoinChallenge />}
            />
            <Route
              path="/challenges/:challenge_id/tasks/:task_id"
              element={<ChallengesTasks />}
            />
            <Route
              path="/challenges/:challenge_id/tasks/:task_id/complete"
              element={<FinishLesson />}
            />
            <Route path="/books" element={<Books />} />

            <Route path="/books/:book_id" element={<BooksInfo />}></Route>
            <Route
              path="/books/:book_id/chapter"
              element={<BooksChapter />}
            ></Route>
            <Route
              path="/courses/:course_id/:chapter_id/:lesson_id"
              element={<LessonContent />}
            />
            <Route
              path="/courses/:course_id/:chapter_id/:lesson_id/complete"
              element={<FinishLesson />}
            />
            {/* <Route path="/courses/:course_id/:chapter_id/:lesson_id/survey" element={<Feedback />} />  */}
            <Route path="/leaderboard" element={<Leaderboard />}></Route>
            <Route path="/simulator" element={<Simulator />} />
            <Route path="/simulator/buy-assets" element={<BuyAssets />} />
            <Route
              path="/simulator/buy-assets/:asset"
              element={<StockView />}
            />

            <Route path="/profile" element={<Profile />} />
            <Route
              path="/profile/subscription-plan"
              element={<SubscriptionPlan />}
            />
            <Route path="/profile/settings" element={<Settings />} />

            <Route path="/profile/language" element={<Language />} />

            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/email-login" element={<EmailLogin />} />
            <Route path="/*" element={<PageNotFound404 />} />
          </>
        ) : (
          <>
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/profile/subscription-plan"
              element={<SubscriptionPlan />}
            />
            <Route path="/profile/settings" element={<Settings />} />
            <Route path="/profile/language" element={<Language />} />

            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/email-login" element={<EmailLogin />} />

            <Route path="/*" element={<PageNotFound404 />} />
          </>
        )}
      </Routes>
    </>
  );
}
