import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import images from "../../Assets/Images";
import { useNavigate } from "react-router-dom";
import Auth from "../../helpers/Auth";
import Footer from "../component/Footer";

import Header from "../component/Header";

export default function Home({ coin }) {
  const navigate = useNavigate();
  const { http, user, errorHandle } = Auth();
  const [currentCourse, setCurrentCourse] = useState();
  const [course, setCourse] = useState();
  const [books, setBooks] = useState();
  const [state, setState] = useState({
    challenge_progress: 0,
  });
  useEffect(() => {
    http
      .get(`/home/${user.id}`)
      .then((res) => {
        console.log(res.data.data);
        setCurrentCourse(res.data.data.course[0]);
        setCourse(res.data.data.course_list);
        setBooks(res.data.data.book_list);
        setState({
          active_lesson_id: res.data.data.next_lesson,
          challenge_id: res.data.data.challenge_id,
          challenge_progress: res.data.data.challenge_progress,
          course_progress: res.data.data.course_progress,
        });
      })
      .catch((err) => {
        console.log(err);
        errorHandle(err);
      });
  }, []);
  // Slider settings for the course slider
  const courseSliderSettings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    dots: false,
    draggable: true,
  };

  // Slider settings for the books slider
  const booksSliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    dots: false,
    draggable: true,
  };
  const startLesson = async (id = null) => {
    const course_id = currentCourse.id;
    const chapter_id = currentCourse.chapters[0].id;
    let lesson_id = state.active_lesson_id;
    if (id) {
      lesson_id = id;
    }
    const result = await http.post(`/start-lesson`, {
      user_id: user.id,
      course_id: course_id,
      chapter_id: chapter_id,
      lesson_id: lesson_id,
    });
    console.log(state, `/courses/${course_id}/${chapter_id}/${lesson_id}`);
    navigate(`/courses/${course_id}/${chapter_id}/${lesson_id}`);
  };

  return (
    <>
      <div className="main_container">
        <Header />

        <div className="new_chapter_box">
          {currentCourse && (
            <div className="my_journey_container">
              <div className="title_box">
                <h4>{currentCourse.name}</h4>
                {/* <p>Open all</p> */}
              </div>
              <div className="chapter_inner">
                <div className="chapter_topBar">
                  <p>{"Chapter " + currentCourse.chapters[0].order_no}</p>
                  <p>{state.course_progress}%</p>
                </div>
                <h4 className="chapter_title">
                  {currentCourse.chapters[0].name}
                </h4>
                <ul
                  className={`chapter_mapBox item_${currentCourse.chapters[0].length}`}
                >
                  {currentCourse.chapters[0].lessons.map((el) => {
                    let is_lesson_complete = false;

                    if (el.user_complete_lessons.length > 0) {
                      is_lesson_complete = true;
                      // el.user_complete_lessons[0].is_lesson_complete;
                    }
                    return (
                      <li
                        key={el.id}
                        className={is_lesson_complete ? "active" : ""}
                        onClick={() => startLesson(el.id)}
                      >
                        {el.order_no < 2 ? (
                          <img
                            src={images["start_icon.png"]}
                            title={el.name}
                            alt="start"
                          />
                        ) : el.type === "quiz" ? (
                          <img
                            src={images["chapter_last.png"]}
                            title={el.name}
                            alt="start"
                          />
                        ) : (
                          <img
                            src={images["chapter_1.png"]}
                            title={el.name}
                            alt="start"
                          />
                        )}
                      </li>
                    );
                  })}
                </ul>
                <div className="chapter_btn_box">
                  <button
                    className="btn_newtheme"
                    onClick={() => startLesson()}
                  >
                    Start
                  </button>
                  <button
                    className="menu_btn"
                    onClick={() =>
                      navigate(`/courses`, {
                        state: { watchlist: "active" },
                      })
                    }
                  >
                    <img src={images["menu.png"]} alt="menu" />
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="course_conatiner">
            <div className="title_box">
              <h4>Courses</h4>
              <p onClick={() => navigate(`/courses`)}>Open all</p>
            </div>
            <div className="course_sliderContainer slick-initialized slick-slider">
              <Slider {...courseSliderSettings}>
                {course &&
                  course.map((el) => {
                    return (
                      <div
                        key={el.id}
                        className="slick-slide slick-cloned"
                        data-slick-index="-2"
                        id=""
                        aria-hidden="true"
                        tabIndex="-1"
                        style={{ width: "226px" }}
                        // onClick={()=>navigate(`/courses`)}
                      >
                        <div>
                          <div
                            style={{
                              width: "100%",
                              display: "inline-block",
                            }}
                          >
                            <div className="course_box">
                              <div className="course_imgBox">
                                <img src={el.image} alt="Course" />
                                <p className="badge_text">
                                  {el.total_lessons + " Modules"}
                                </p>
                              </div>
                              <div className="course_content_box">
                                <p>{el.name}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
          <div className="new_challenges_box">
            <div className="left_site">
              <h2>Challenges</h2>
              <p>Daily training: 15 mins</p>
              <button
                className="theme1"
                onClick={() => navigate(`/challenges`)}
              >
                Continue
              </button>
            </div>
            <div className="right_site">
              <div className="card">
                <div className="percent">
                  <svg>
                    <circle cx="55" cy="55" r="45"></circle>
                    <circle
                      cx="55"
                      cy="55"
                      r="45"
                      style={{
                        "--percent": Math.round(state.challenge_progress),
                        strokeDasharray: "592px",
                      }}
                    ></circle>
                  </svg>
                  <div className="number">
                    <h3>
                      {Math.round(state.challenge_progress)}
                      <span>%</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new_bookBox">
            <div className="title_box">
              <h4>Books</h4>
              <p onClick={() => navigate(`/books`)}>Open all</p>
            </div>

            <div className="books_sliderContainer slick-initialized slick-slider">
              <Slider {...booksSliderSettings}>
                {books &&
                  books.map((el) => {
                    return (
                      <div
                        key={el.id}
                        className="slick-slide slick-cloned"
                        data-slick-index="-3"
                        id=""
                        aria-hidden="true"
                        tabIndex="-1"
                        style={{ width: "151px" }}
                        onClick={() => navigate(`/books/${el.id}`)}
                      >
                        <div>
                          <div
                            style={{
                              width: "100%",
                              display: "inline-block",
                            }}
                          >
                            <div className="course_box">
                              <div className="course_imgBox">
                                <img src={el.image} alt="book" />
                              </div>
                              <div className="course_content_box">
                                <p>{el.name}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
