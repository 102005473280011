import React, { useEffect, useState,useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import images from "../../Assets/Images";
import Auth from "../../helpers/Auth";
import Coin from "../component/Coin";

export default function StockView() {
  const navigate = useNavigate();
  const { http, user } = Auth();
  const params = useParams();
  const [assets, setAssets] = useState({});
  const [trade, setTrade] = useState();
  const [input, setInput] = useState({
    qty: 1,
    usd: 1,
    trade_trigger: false,
    next_url: "_blank",
    count: 0,
  });
  const [config, setConfig] = useState({
    frameElementId: `tradingview_1D`,
    symbol: params.asset,
    range: "1D",
  });
  const getUserTradeDetails = () => {
    http
      .get(`/user-trade-details/${params.asset}/${user.id}`)
      .then((res) => {
        setTrade(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSimulatorStockDetails = () => {
    http
      .get(`/simulator-stock-details/${params.asset}`)
      .then((res) => {
        // console.log(res.data.data);
        setAssets(res.data.data);
        getLatestTradePrice(res.data.data.type, res.data.data.ticker_name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLatestTradePrice = (type, ticker) => {
    http
      .post(`/latest-trade-price/${type}/${ticker}`, {
        next_url: input.next_url,
      })
      .then((res) => {
        console.log(res);
        if (assets && assets.type) {
          setAssets({ ...assets, close_price: res.data.data.latest_price });
        }
        setInput({
          ...input,
          next_url: res.data.data.apiUrl,
          // usd:res.data.data.latest_price 
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSimulatorStockDetails();
    getUserTradeDetails();
  }, []);

  useEffect(() => {
    const intervalId = setTimeout(() => {
      // console.log(assets);
      setInput({ ...input, count: input.count + 1 });
      getLatestTradePrice(assets.type, assets.ticker_name); // Increment count every second
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [assets, input.count]);

  const buySellStock = (type) => {
    http
      .post(`/simulator-stock-buy-sell`, {
        type: type,
        ltp: assets.close_price,
        qty: input.qty,
        simulator_id: assets.id,
        user_id: user.id,
        ticker: params.asset,
      })
      .then((res) => {
        getUserTradeDetails();
        console.log(res);
      });
  };

  const setAssetQty = (e) => {
    // console.log(e.target.value)
    setInput({
      ...input,
      usd: assets.close_price * e.target.value,
      qty: e.target.value,
    });
  };
  const setAssetPrice = (e) => {
    setInput({
      ...input,
      usd: e.target.value,
      qty: e.target.value / assets.close_price,
    });
  };
  return (
    <>
      <div className="main_container">
        <div className="top_header">
          <div
            className="logo_box"
            onClick={() => navigate("/simulator/buy-assets")}
          >
            <img src={images["left_ang.svg"]} alt="" />
          </div>
          <div>
            <p className="stocks_name">{assets && assets.ticker_name}</p>
          </div>
          <div className="coin_store"></div>
        </div>
        <div className="stocframeElementIdks_view_box">
          <div className="stock_tabBox">
            <ul>
              <li className="active">Chart</li>
              <li>Details</li>
            </ul>
          </div>
          <div className="view_box">
            {config && (
              <iframe
                title="advanced chart TradingView widget"
                lang="en"
                id={"tradingview_" + config.range}
                frameborder="0"
                allowtransparency="true"
                scrolling="no"
                allowfullscreen="true"
                src={`https://s.tradingview.com/widgetembed/?frameElementId=tradingview_${config.range}&symbol=${config.symbol}&interval=${config.range}&theme=light&style=1&toolbarbg=f1f3f6&withdateranges=false&hide_top_toolbar=true&saveimage=false&studies=[]`}
                style={{
                  width: "100%",
                  height: "100%",
                  margin: "0px !important",
                  padding: "0px !important",
                }}
              ></iframe>
            )}
          </div>
        </div>

        <div className="chapter_box buy_sell_inner">
          <ul className="period_tab">
            <li
              className={config.range === "1D" ? "active" : ""}
              onClick={() =>
                setConfig({
                  ...config,
                  range: "1D",
                  frameElementId: "tradingview_1D",
                })
              }
            >
              1D
            </li>
            <li
              className={config.range === "5D" ? "active" : ""}
              onClick={() =>
                setConfig({
                  ...config,
                  range: "5D",
                  frameElementId: "tradingview_5D",
                })
              }
            >
              5D
            </li>
            <li
              className={config.range === "1M" ? "active" : ""}
              onClick={() =>
                setConfig({
                  ...config,
                  range: "1M",
                  frameElementId: "tradingview_1M",
                })
              }
            >
              1M
            </li>
            <li
              className={config.range === "3M" ? "active" : ""}
              onClick={() =>
                setConfig({
                  ...config,
                  range: "3M",
                  frameElementId: "tradingview_3M",
                })
              }
            >
              3M
            </li>
            <li
              className={config.range === "6M" ? "active" : ""}
              onClick={() =>
                setConfig({
                  ...config,
                  range: "6M",
                  frameElementId: "tradingview_6M",
                })
              }
            >
              6M
            </li>
          </ul>
          {/* <div className="how_itWork_box">
            <div className="content_box">
              <img src={images["fire.svg"]} alt="fire" />
              <p>
                <span>Wondering if it's the right time to sell?</span> Our guide
                helps you make informed decisions on when to cash in!
              </p>
            </div>
            <button>See how it works</button>
          </div> */}
          <div className="sell_buyBox">
            <div className="convert_dollarBox">
              <div className="input_box">
                <label>{assets && assets.ticker_name}</label>
                <input
                  type="number"
                  placeholder="0"
                  value={input.qty}
                  onChange={setAssetQty}
                />
              </div>
              <div className="input_box">
                <label>USD</label>
                <input
                  type="number"
                  placeholder="0"
                  value={input.usd}
                  onChange={setAssetPrice}
                />
              </div>
            </div>
            <div className="availableTotal_list">
              <ul>
                <li>
                  <p>Available cash</p>{" "}
                  <span>
                    $<Coin only_balance={true} />
                  </span>
                </li>
              </ul>
            </div>
            <div className="sellBuy_button_container">
              <button onClick={() => buySellStock("sell")}>
                <p>Sell</p>
                <h5>{assets && assets.close_price}</h5>
              </button>
              <button onClick={() => buySellStock("buy")}>
                <p>Buy</p>
                <h5>{assets && assets.close_price}</h5>
              </button>
            </div>
          </div>
          <div className="courses_titleBar_1">
            {/* <h3>Active Trades</h3> */}
            {/* {trade &&
              trade.trade_transctions.map((el) => {
                return (
                  <div
                  key={el.id}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <div>{el.left_qty}</div>
                    <div>{el.}</div>
                    <div>gf</div>
                    <div>gfd</div>
                  </div>
                );
              })} */}
            <table>
              <tr>
                <th>qty</th>
                <th>buy-price</th>
                <th>market-price</th>
                <th>profit/loss</th>
              </tr>
              {trade &&
                trade.trade_transctions.map((el) => {
                  return (
                    <tr
                      key={el.id}
                      style={{
                        backgroundColor:
                          el.type === "buy"
                            ? "rgb(25, 170, 50)"
                            : "rgb(212, 51, 51)",
                        color: "white",
                      }}
                    >
                      <td>{el.left_qty}</td>
                      <td>{el.order_place_price}</td>
                      <td>{assets.close_price}</td>
                      <td
                        style={{
                          backgroundColor:
                            el.type === "sell"
                              ? "rgb(25, 170, 50)"
                              : "rgb(212, 51, 51)",
                        }}
                      >
                        {el.type === "sell"
                          ? (
                              (assets.close_price - el.order_place_price) *
                              el.left_qty
                            ).toFixed(2)
                          : (
                              (assets.close_price - el.order_place_price) *
                              -el.left_qty
                            ).toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
